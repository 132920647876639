<template>
  <div>
    <v-data-table
      dense
      data-cy="versioned"
      :headers="headers"
      :items="dataSources"
      :items-per-page="10"
    >
      <template v-slot:[`item.actions`]="{ item }">
        <tooltip message="View">
          <friss-icon
            data-cy="view"
            class="mr-3"
            @click="selectedDataSourceId = item.id"
            name="information"
          >
          </friss-icon>
        </tooltip>

        <tooltip message="Delete">
          <confirm
            v-if="$permissions.canModifyDataSource"
            :title="isLastRemaining ? 'Delete data source' : 'Delete version'"
            :message="
              isLastRemaining
                ? 'Are you sure to delete the data source? This will delete the definition and all associated expression. Do you want to continue?'
                : 'Are you sure to delete this data source version?'
            "
            yesText="Delete"
            noText="Cancel"
            @confirmed="deleteItem(item.id)"
          >
            <friss-icon data-cy="deletepbl" name="delete"></friss-icon>
          </confirm>
        </tooltip>
      </template>

      <template v-slot:[`item.createdDate`]="{ item }">
        <span :data-created-date="item.createdDate">{{ item.createdDate | date }}</span>
      </template>
      <template v-slot:[`item.importedDate`]="{ item }">
        <span :data-imported-date="item.importedDate">{{
          item.importedDate | date
        }}</span>
      </template>
    </v-data-table>

    <dataSource
      v-if="selectedDataSourceId !== null"
      :datasourceId="selectedDataSourceId"
      :isDraft="false"
      @saved="getAll"
      @close="selectedDataSourceId = null"
    ></dataSource>
  </div>
</template>

<script>
import confirm from "@/components/confirm.dialog.vue";
import tooltip from "@/components/tooltip.vue";
import { API } from "@/datahub-api";
import dataSource from "@/views/datasource/datasource.vue";
export default {
  components: { confirm, tooltip, dataSource },
  name: "versionedDataSources",
  data() {
    return {
      selectedDataSourceId: null,
      headers: [
        { text: "Created By", value: "createdBy" },
        { text: "Created Date", value: "createdDate" },
        { text: "Imported by", value: "importedBy" },
        { text: "Imported date", value: "importedDate" },
        {
          text: "Actions",
          value: "actions",
          sortable: false,
          align: "right",
        },
      ],
      dataSources: [],
    };
  },
  props: ["dataSourceId", "dataproviderId"],
  async mounted() {
    await this.getAll();
  },
  methods: {
    async getAll() {
      var response = await API.dataSourceService.getHistory(this.dataSourceId);
      if (response.isSuccess) {
        this.dataSources = response.result || [];
      }
    },
    async deleteItem(id) {
      await API.dataSourceService.delete(id);
      if (this.isLastRemaining) {
        this.dataSources = [];
      } else {
        await this.getAll();
      }
      this.$emit("onDeleted");
    },
  },
  computed: {
    isLastRemaining: function () {
      return this.dataSources.length === 1;
    },
  },
};
</script>
